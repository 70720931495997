var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - Import Organisations" }
      }),
      _c("gov-back-link", { attrs: { to: { name: "organisations-index" } } }, [
        _vm._v("Back to Organisations")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "full" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Bulk upload organisations")
                  ]),
                  _c("gov-body", [
                    _c("p", [
                      _vm._v(
                        "\n            This tool allows you to upload the details of more than one\n            organisation into the platform. You can add up to 5000\n            organisations in a single document.\n          "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n            The import tool requires all documents to be either in the .xls or\n            .xlsx format. Please note that .csv files are not supported.\n          "
                      )
                    ]),
                    _c(
                      "p",
                      [
                        _c(
                          "gov-link",
                          {
                            attrs: { href: _vm.exampleSpreadsheetDownloadLink }
                          },
                          [_vm._v("An example template can be downloaded here")]
                        ),
                        _vm._v(
                          "\n            for you to populate offline and upload below. The uploaded\n            document must follow this template and new columns can not be\n            added.\n          "
                        )
                      ],
                      1
                    ),
                    _c("p", [
                      _vm._v(
                        "\n            Upon import, the tool will check the data you have provided to\n            make sure it is valid. If there are any errors with the data, none\n            of the data will be imported and you will be informed of the\n            specific rows and data that are invalid.\n          "
                      )
                    ])
                  ]),
                  _c("spreadsheet-import-form", {
                    attrs: {
                      errors: _vm.form.$errors,
                      spreadsheet: _vm.file,
                      feedback: _vm.formResponse
                    },
                    on: {
                      "update:spreadsheet": function($event) {
                        _vm.file = $event
                      },
                      clear: _vm.resetForm
                    }
                  }),
                  _vm.form.$submitting
                    ? _c(
                        "gov-button",
                        { attrs: { disabled: "", type: "submit" } },
                        [_vm._v("Uploading...")]
                      )
                    : _c(
                        "gov-button",
                        {
                          attrs: { type: "submit" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("Upload")]
                      ),
                  _vm.form.$errors.any() ? _c("ck-submit-error") : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.invalidRows.length || _vm.duplicateRows.length
            ? _c(
                "gov-grid-row",
                [
                  _c(
                    "gov-grid-column",
                    { attrs: { width: "full" } },
                    [
                      _c("spreadsheet-import-errors", {
                        attrs: {
                          fields: _vm.fields,
                          invalidRows: _vm.invalidRows,
                          duplicateRows: _vm.duplicateRows
                        },
                        on: { ignoreDuplicate: _vm.ignoreDuplicate }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }